.faq {
    padding: 50px 20px;
    text-align: center;
    background-color: #f4f4f4; /* Light grey background */
}

.faq h2 {
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: underline;
}

.accordion {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

.accordion-item {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.accordion-title {
    padding: 15px;
    cursor: pointer;
    background-color: #e9e9e9;
}

.accordion-content {
    padding: 15px;
    background-color: #fff;
}

.request-demo-btn {
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #7b4a4a;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.request-demo-btn:hover {
    background-color: #6b3f3f;
}

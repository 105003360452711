.testimonial {
    padding: 50px 0;
    text-align: center;
    margin: 50px;
}

.testimonial h2 {
    font-weight: 400;
    margin-bottom: 40px;
    text-decoration: underline;
    font-size: 35px;
}

.testimonials {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.testimonial-box {
    padding: 10px;
    flex-basis: 150px;
    flex-grow: 1;
}

.testimonial-box p {
    text-align: left;
    color: #222;
}

.testimonial-box strong {
    color: #333;
}

.start-now-btn {
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #7b4a4a;
    color: white;
    border: none;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-weight: bold;
}

.start-now-btn:hover {
    background-color: #6b3f3f;
}

.footer {
    background-color: #00a2c7;
    color: white;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer-logo {
    background-color: #f2f2f2; /* Light grey for the logo */
    padding: 10px;
}

.footer-logo h1 {
    color: #222;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 600px;
    text-align: left;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    text-decoration: none;
    color: white;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-copyright {
    font-size: 0.9em;
    color: #e9e9e9;
}

.supported-affiliate {
    padding: 50px 20px;
    text-align: center;
}

.supported-affiliate h2 {
    font-weight: 400;
    font-size: 35px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.supported-affiliate p {
    margin-bottom: 40px;
    color: #555;
}

.supported-affiliate .features {
    display: flex;
    flex-direction: row;
    gap: 30px;
    max-width: 800px;
    margin: auto;

}

.features .left {
    min-width: 250px;
    background-image: url('../img/supported-affilate.webp');
    background-size: cover;
    box-shadow: 0px 2px 25px 0px black;
}
.feature {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    text-align: left;
}

.feature .icon {
    font-size: 60px;
    font-weight: 400;
    color: #2c3e50; /* Dark blue color */
    margin-top: 25px;
}

.feature h3 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
}

.request-access-btn {
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #7b4a4a;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.request-access-btn:hover {
    background-color: #6b3f3f;
}

.hero {
    display: flex;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
}

.hero-content {
    text-align: center;
}
.hero-content h1{
    text-align: center;
    font-size: 60px;
    font-weight: 400;
}

.hero-content h1 .top {
    font-size: 50px;
}

.hero-content p {
    font-size: 25px;
    text-align: center;
}

.hero-content svg {
    margin-top: 40px;
}
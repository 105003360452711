.integration {
    padding: 50px 0;
    text-align: center;
    margin: 20px;
    background: aliceblue;
    box-shadow: 0px 10px 10px 5px #b3b3b3;
}

.integration h2 {
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: underline;
    font-size: 35px;
    font-weight: 400;
}

.steps {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 700px;
    margin: auto;
}

.step {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 800px;
    text-align: left;
}

.icon {
    font-size: 45px;
    color: #2c3e50; /* Dark blue color */
}

.step-content h3 {
    font-weight: bold;
    color: #222;
    font-size: 25px;
}

.step-content p {
    color: #555;
}

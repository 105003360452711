.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #00a2c7; /* Blue background color */
}

.logo {
    background-color: #f2f2f2; /* Light grey background for logo */
    padding: 10px;
}

.logo h1 {
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0;
    color: #222; /* Dark color for text */
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav li {
    margin-left: 20px;
}

.nav a {
    text-decoration: none;
    color: white; /* White color for the navigation links */
    font-family: Arial, sans-serif;
}

.nav a:hover {
    text-decoration: underline; /* Underline effect on hover */
}

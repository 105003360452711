.infrastructure {
    padding: 50px 20px;
    text-align: center;
}

.infrastructure h2 {
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: underline;
}

.infrastructure-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
}

.infrastructure-info {
    text-align: left;
    max-width: 300px;
}

.infrastructure-stats {
    text-align: left;
    max-width: 500px;
}

.infrastructure .learn-more-btn {
    margin-top: 40px;
    padding: 10px 20px;
    background-color: #7b4a4a;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.learn-more-btn:hover {
    background-color: #6b3f3f;
}

.pricing {
    padding: 50px 20px;
    text-align: center;
    background-color: #f4f4f4; /* Light grey background */
}

.pricing h2 {
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: underline;
}

.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.pricing-card {
    background-color: #e9f9f9;
    padding: 30px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.pricing-card h3 {
    margin-bottom: 10px;
    font-weight: bold;
}

.cost {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 20px;
    color: #555;
}

.features {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    color: #555;
}

.request-invite-btn {
    background-color: #7b4a4a;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.request-invite-btn:hover {
    background-color: #6b3f3f;
}
